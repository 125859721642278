import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FloatInput from 'src/components/Common/FloatInput';
import { CHECK_VALUE_IN_ARRAY } from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { courierDropdownAllDataForJobLoading } from 'src/store/actions';
import TextEditor from 'src/components/atoms/TextEditor';
import { FieldArray } from 'formik';
import FeatherIcon from "feather-icons-react"
import Button from 'src/components/Common/button';
import TooltipMEL from '../Tooltip';
import OutgoingDispatchList from 'src/pages/Dispatch/OutGoingDispatch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDateMEL } from 'src/helpers/common_helpers';
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText';
interface TabProps {
    activeTab: string | number;
    status: string;
    handleChange?: any;
    onBlur?: () => void;
    values: any;
    touch: any;
    error: any;
    validationConfiguration?: any;
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    accordion?: any;
    changeAccorditionHandler?: any;
    changeFlow: any;
}

const OutgoingCourier = (props: TabProps) => {

    const { employeeList, courierList, courierAllList,technicianList } = useSelector((state: RootReducerState) => state.jobsheetReducer);
    const dispatch = useDispatch();

    const courierDropList = courierAllList?.length > 0 ? (courierAllList.map((eachCourier: any) => {
        let data: any = {};
        data['value'] = eachCourier.id;
        data['label'] = eachCourier.companyName == "Other" ? `${eachCourier.otherCompany} ${eachCourier?.packageReceiverName ? `courier :(${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}` : `${eachCourier.companyName} ${eachCourier?.packageReceiverName ? `courier : (${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" : eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}`;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : [];

    // const courierDropList = Array.from({ length: 100000 }).map((_, i) => ({ label: `Item #${i}`, value: i }));


    const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
        let data: any = {};
        data['value'] = eachEmployee.userId;
        data['label'] = eachEmployee.name;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : [];

    console.log(technicianList);
    

    const franchiseDropdownList = technicianList?.length > 0 ? (technicianList.map((eachEmployee: any) => {
        let data: any = {};
        data['value'] = eachEmployee.userId;
        data['label'] = eachEmployee.franchiseName;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : [];

    useEffect(() => {
        dispatch(courierDropdownAllDataForJobLoading());
    }, []);

    const deliveryType = [
        { "value": "Hand Carry", "label": "Hand Carry" },
        { "value": "Courier", "label": "Courier" },
    ];
    const handleAddPendingComments = (values: any, push: any,index:any) => {
        debugger
        if(values.packedBy!="" && values.packedDate!="" && values.deliveryType!="" && values.approximateValue!=="" && values.notes!=""  ){

        push({
            packedBy: "",
            packedDate: "",
            deliveryDate: "",
            deliveryType:"",
            deliveredTo:  "",
            deliveredById:  "",
            deliveredUserType:"",
            approximateValue: 0,
            courierId: "",
            notes: "",
            id:0
        });
        let values = {
            ...props.values,
            outgoingDispatchListNew:[...props.values.outgoingDispatchListNew,{
                packedBy: "",
                packedDate: "",
                deliveryDate: "",
                deliveryType:"",
                deliveredTo:  "",
                deliveredById:  "",
                deliveredUserType:"",
                approximateValue: 0,
                courierId: "",
                notes: "",
                id:0
            }]
        }
        props.changeFlow(values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
    }else{
        // const fieldsToClear = [
        //     'packedBy',
        //     'packedDate',
        //     'deliveryDate',
        //     'deliveryType',
        //     'deliveredTo',
        //     'deliveredById',
        //     'approximateValue',
        //     'courierId',
        //     'notes',
        //  ];
         
        //  for (let i = 0; i < props.values.outgoingDispatchListNew.length; i++) {
        //     fieldsToClear.forEach((field) => {
        //        const fieldName = `outgoingDispatchListNew[${i}].${field}`;
        //        props.setFieldTouched(fieldName, true);
        //     });
        //  }
    }
       
      };


      const DeliveredBy = (rowData:any)=>{
        if(rowData.deliveredUserType == "Franchise"){
            return franchiseDropdownList?.find((val:any)=>val.value == rowData.deliveredById)?.label
        }else{
            return employeeDropList?.find((val:any)=>val.value == rowData.deliveredById)?.label
        }
      }

      const EditValue = (rowData:any) => {
        props.setFieldValue(`outgoingDispatchListNew[0].packedBy`,rowData.packedBy)
        props.setFieldValue(`outgoingDispatchListNew[0].id`,rowData.id)
        props.setFieldValue(`outgoingDispatchListNew[0].courierId`,rowData.courierId)
        props.setFieldValue(`outgoingDispatchListNew[0].packedDate`,rowData.packedDate)
        props.setFieldValue(`outgoingDispatchListNew[0].notes`,rowData.notes)
        props.setFieldValue(`outgoingDispatchListNew[0].deliveryDate`,rowData.deliveryDate)
        props.setFieldValue(`outgoingDispatchListNew[0].deliveredById`,rowData.deliveredById)
        props.setFieldValue(`outgoingDispatchListNew[0].deliveryType`,rowData.deliveryType)
        props.setFieldValue(`outgoingDispatchListNew[0].deliveredTo`,rowData.deliveredTo)
        props.setFieldValue(`outgoingDispatchListNew[0].deliveredUserType`,rowData.deliveredUserType)
        props.setFieldValue(`outgoingDispatchListNew[0].approximateValue`,rowData.approximateValue)

        let values = {
            ...props.values,
            outgoingDispatchListNew:[{
                packedBy: rowData.packedBy,
                packedDate: rowData.packedDate,
                deliveryDate: rowData.deliveryDate,
                deliveryType:rowData.deliveryType,
                deliveredTo:  rowData.deliveredTo,
                deliveredById:  rowData.deliveredById,
                deliveredUserType:rowData.deliveredUserType,
                approximateValue: rowData.approximateValue,
                courierId: rowData.courierId,
                notes: rowData.notes,
                id:rowData.id
            }]
        }
        props.changeFlow(values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
      }
      const EditTemplate = (rowData:any) => {
        return(
            <>
            <FeatherIcon icon="edit" onClick={()=>EditValue(rowData)}/>
            </>
        )
      }

    return (
        <div className={`job-sheet-tab-content  ${props.activeTab === "Outgoing Courier" ? "" : "d-none"}`} >
            <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
                <div className='dealer-title-header font-size-15 border-bottom'>
                    <span className='pull-left'>Outgoing Dispatch</span>
                </div>
                <FieldArray name="outgoingDispatchListNew">
                {({ insert, remove, push }) => (
                  <div >
                    {props.values.outgoingDispatchListNew?.length > 0 &&
                      props.values.outgoingDispatchListNew.map(
                        (value: any, index: any) => {
                          return (
                          <div key={index}>
                <CardBody className='p-0'>
                    <div className='px-3 pb-3'>
                        <Row className={''} id="customer-add-panel">
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Packed by"
                                    type="text"
                                    name={`outgoingDispatchListNew[${index}].packedBy`}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.packedBy}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["packedBy"] && props.error.outgoingDispatchListNew?.[index]?.["packedBy"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].packedBy`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].packedBy`)}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Packed date"
                                    type="date"
                                    name={`outgoingDispatchListNew[${index}].packedDate`}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.packedDate}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["packedDate"] && props.error.outgoingDispatchListNew?.[index]?.["packedDate"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [],`outgoingDispatchListNew[${index}].packedDate`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [],`outgoingDispatchListNew[${index}].packedDate`)}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Delivery date"
                                    type="datetime"
                                    name={`outgoingDispatchListNew[${index}].deliveryDate`}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.deliveryDate}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["deliveryDate"] && props.error.outgoingDispatchListNew?.[index]?.["deliveryDate"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].deliveryDate`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].deliveryDate`)}
                                    // today={true}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    type="dropdown"
                                    name={`outgoingDispatchListNew[${index}].deliveryType`}
                                    label="Delivery type"
                                    options={deliveryType}
                                    onChange={(e: any) => {
                                        debugger;
                                        value['deliveryType'] = e.target.value;
                                        props.changeFlow(props.values, props.values?.status, props.setFieldError, props.setFieldTouched, props.setFieldValue);
                                        props.handleChange(e);

                                    }}
                                    onBlur={props.onBlur}
                                    value={value.deliveryType}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["deliveryType"] && props.error.outgoingDispatchListNew?.[index]?.["deliveryType"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].deliveryType`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].deliveryType`)}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Delivered to"
                                    type="text"
                                    name={`outgoingDispatchListNew[${index}].deliveredTo`}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.deliveredTo}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["deliveredTo"] && props.error.outgoingDispatchListNew?.[index]?.["deliveredTo"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].deliveredTo`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].deliveredTo`)}
                                />
                            </Col>
                            {props.values.isExchange &&
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    name={`outgoingDispatchListNew[${index}].deliveredUserType`}
                                    label="Delivered by User Type"
                                    options={[
                                        {label:"Mount",value:"Mount"},
                                        {label:"Franchise",value:"Franchise"},
                                    ]}
                                    type="dropdown"
                                    onChange={(e)=>{props.handleChange(e);props.setFieldValue(`outgoingDispatchListNew[${index}].deliveredById`,"")}}
                                    onBlur={props.onBlur}
                                    value={value.deliveredUserType}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["deliveredUserType"] && props.error.outgoingDispatchListNew?.[index]?.["deliveredUserType"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].deliveredUserType`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].deliveredUserType`)}
                                />
                            </Col>
                            }
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    name={`outgoingDispatchListNew[${index}].deliveredById`}
                                    label="Delivered by"
                                    options={value.deliveredUserType == "Franchise" ? franchiseDropdownList : employeeDropList}
                                    type="dropdown"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.deliveredById}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["deliveredById"] && props.error.outgoingDispatchListNew?.[index]?.["deliveredById"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].deliveredById`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].deliveredById`)}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    label="Approximate value"
                                    type="number"
                                    name={`outgoingDispatchListNew[${index}].approximateValue`}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.approximateValue}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["approximateValue"] && props.error.outgoingDispatchListNew?.[index]?.["approximateValue"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].approximateValue`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].approximateValue`)}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    name={`outgoingDispatchListNew[${index}].courierId`}
                                    label="Courier"
                                    options={courierDropList}
                                    type="dropdown"
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.courierId}
                                    invalid={props.touch.outgoingDispatchListNew?.[index]?.["courierId"] && props.error.outgoingDispatchListNew?.[index]?.["courierId"] ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].courierId`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].courierId`)}
                                />
                            </Col>
                            <Col lg={6} md={6} className="mt-mb-textarea">
                                <div className="d-flex align-items-start">
                                    <div className="d-block  w-100">
                                        {/* <FloatInput
                                            label="Notes"
                                            type="textarea"
                                            name="notes"
                                            onChange={props.handleChange}
                                            onBlur={props.onBlur}
                                            value={props.values.notes}
                                            invalid={props.touch["notes"] && props.error["notes"] ? true : false}
                                            required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "notes")}
                                            disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "notes")}
                                        /> */}

                                        <TextEditor
                                            onChange={props.handleChange}
                                            labelName='Notes'
                                            name={`outgoingDispatchListNew[${index}].notes`}
                                            required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `outgoingDispatchListNew[${index}].notes`) ? true : false}
                                            value={value.notes}
                                            invalid={props.touch.outgoingDispatchListNew?.[index]?.["notes"] && props.error.outgoingDispatchListNew?.[index]?.["notes"] ? true : false}
                                            setFieldTouched={props.setFieldTouched}
                                            setFieldValue={props.setFieldValue}
                                            onBlur={props.onBlur}
                                            readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `outgoingDispatchListNew[${index}].notes`) ? true : false}
                                        />
                                    </div>


                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-end">
                        {/* {
                        ((props.values.outgoingDispatchListNew?.length == index+1) && props.values.isExchange == true) &&
                    <Button buttonName='Add' className='btn btn-primary' onClick={()=>{handleAddPendingComments(value,push,index)
                    }} type='button'/>
                        } */}
                    </div>
                    <hr />
                </CardBody>
                </div>
                          )
                        })}
                        
                        </div>
                )}
                </FieldArray>
                {props.values.outgoingDispatchList.length>0 ?
                <div className="card ps-0 pe-0 mt-2" id="table-border">
                  <DataTable size='large' value={props.values.outgoingDispatchList} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                    <Column field="packedBy" header="Packed by" className=''></Column>
                    <Column field="packedDate" header="Packed Date" className='' body={(rowData:any)=>formatDateMEL(rowData.packedDate)}></Column>
                    <Column field="deliveryDate" header="Delivery Date" className='' body={(rowData:any)=>formatDateMEL(rowData.deliveryDate)}></Column>
                    <Column field="deliveryType" header="Delivery Type" className=''></Column>
                    <Column field="deliveredTo" header="Delivered To" className=''></Column>
                    <Column field="deliveredUserType" header="Delivered By Type" className=''></Column>
                    <Column field="deliveredById" header="Delivered By" className='' body={(rowData:any)=>DeliveredBy(rowData)}></Column>
                    <Column field="approximateValue" header="Approximate Value" className=''></Column>
                    <Column field="notes" header="Notes" className='' body={(rowData:any)=><HtmltoPlainText htmlString={rowData.notes}/>}></Column>
                    <Column field="notes" header="Edit" className='' body={(rowData:any)=>EditTemplate(rowData)}></Column>
                  </DataTable>
                </div>
                : null }
            </Card>
        </div>
    );
};

export default OutgoingCourier;