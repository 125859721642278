import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { RootReducerState } from 'src/store/reducers'
import "../../SubUserManagement/subUserstyle.scss"
import {
  hasAdminOrEmployeeRoleHandler,
  hasCustomerRoleHandler,
  hasDealerRoleHandler,
  hasFranchiseRoleHandler,
  onlyAdminRoleHandler,
} from 'src/helpers/common_helpers';
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import {INTENT_STATUS } from 'src/constants/commonConstants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { clearStockTransferMessageData, emailStockTransferLoading, employeeListforStockTransfer, franchiseListforStockTransfer, getStockTransferDetail, printStockTransferLoading, resetStockTransferValue } from 'src/store/stockTransfer/action';
import { StockTransferDetailById } from 'src/models/pages/stockTransferModel';
import { getProfile } from 'src/store/profile/actions';

const ViewStockTransferComponent = () => {
  const { id, sub_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stockTransferDetail, franchiseList, error, message,mailmessage,employeeList, loading } = useSelector((state: RootReducerState) => state.StockTransferReducer);
  const {  profile } = useSelector(
    (state: RootReducerState) => state.profilesReducer
  );

  const [stockTransferDetails, setStockTransferDetails] = useState<any>([])
  const [visible, setVisible] = useState(false);
  const [generateSKUModal, setGenerateSKUModal] = useState<boolean>(false)
  const { showToast } = useToast();
  const isDealerLogin = hasDealerRoleHandler();
  const isFranchise = hasFranchiseRoleHandler();
  const isCustomer = hasCustomerRoleHandler();

  useEffect(() => {
    if (id) {
      dispatch(franchiseListforStockTransfer())
      dispatch(employeeListforStockTransfer());
      dispatch(getStockTransferDetail(id));
    }

    return () => {
      dispatch(resetStockTransferValue())
      dispatch(clearStockTransferMessageData())
    }
  }, [id])

  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearStockTransferMessageData());
      navigate(`${ROUTER.STOCK_TRANSFER_GRID}`);

    }
    if (mailmessage) {
      showToast(mailmessage, { type: "success" });
      dispatch(clearStockTransferMessageData());

    }
  }, [error, message,mailmessage]);

  useEffect(()=>{
    dispatch(getProfile());
    return () => {
      dispatch(resetStockTransferValue())
      dispatch(clearStockTransferMessageData())
    }
  },[])

  useEffect(() => {
    const stockTransferDetails = (stockTransferDetail as StockTransferDetailById)?.stockTransferDetails?.filter((item: any) => (item.id == sub_id))
    if (stockTransferDetails?.length > 0) {
      setStockTransferDetails(stockTransferDetails)
    }

  }, [stockTransferDetail])


  const FranchiseTemplate = (rowData: any) => {
    return (
      <>
        {
          (isDealerLogin || isFranchise || isCustomer) ? rowData : <Link to={`${ROUTER.FRANCHISEE_VIEW}/${stockTransferDetail?.franchiseId}`}>{rowData}</Link>
        }
      </>
    );
  };

  const EmployeeTemplate = (rowData: any) => {
    return (
      <>
        {
          (isDealerLogin || isFranchise || isCustomer) ? rowData : <Link to={`${ROUTER.USERS_VIEW}/${stockTransferDetail?.receiverId}`}>{rowData}</Link>
        }
      </>
    );
  };
  const userView = {
    generalDetails: [
      {
        name: "Sender",
        value: franchiseList ? FranchiseTemplate(franchiseList?.find((eachValue: any) => eachValue.id == stockTransferDetail?.franchiseId)?.name) : ""
      },
      {
        name: "Receiver",
        value:stockTransferDetail?.receiverUserType == "Franchise" ?  (franchiseList ? FranchiseTemplate(franchiseList?.find((eachValue: any) => eachValue.id == stockTransferDetail?.receiverId)?.name) : "") : employeeList ? EmployeeTemplate(employeeList?.find((eachValue: any) => eachValue.id == stockTransferDetail?.receiverId)?.name) : ""
      },
      {
        name: "Stock transfer Number",
        value: stockTransferDetail?.stockTransferNumber
      },
    ],

  }

 





  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    const inputElement = document.getElementById('ScanField');
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearStockTransferMessageData());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };


  const StockTransferDetailnondetail:any = stockTransferDetail?.stockTransferDetails?.filter((item: any) => item.isDeleted != true)

  const handleEditStockTransfer = () => {
    navigate(`${ROUTER.STOCK_TRANSFER_EDIT}/${id}`)
  }

  let isAdmin = hasAdminOrEmployeeRoleHandler();
  let isApprovedStatus = true;
  isApprovedStatus = ((isAdmin && (stockTransferDetail?.status == INTENT_STATUS.REQUESTED || stockTransferDetail?.status == INTENT_STATUS.APPROVED || stockTransferDetail?.status == INTENT_STATUS.PENDING_DISPATCH)) || (!isAdmin && (stockTransferDetail?.status == INTENT_STATUS.REQUESTED || stockTransferDetail?.status == INTENT_STATUS.COURIERED))) ? true : false

  const hasEdit = ((hasAdminOrEmployeeRoleHandler() && (stockTransferDetail?.status == INTENT_STATUS.REQUESTED || stockTransferDetail?.status == INTENT_STATUS.APPROVED || stockTransferDetail?.status == INTENT_STATUS.PENDING_DISPATCH)) || (hasFranchiseRoleHandler() && profile?.extraProperties?.FranchiseId == stockTransferDetail?.franchiseId && (stockTransferDetail?.status == INTENT_STATUS.PENDING_DISPATCH || stockTransferDetail?.status == INTENT_STATUS.REQUESTED))   || (onlyAdminRoleHandler() || (stockTransferDetails.receiverUserType=="Franchise" ? (profile?.extraProperties?.FranchiseId == stockTransferDetails.ReceiverId && hasFranchiseRoleHandler()) : (profile?.extraProperties?.MelEmployeeId == stockTransferDetails.ReceiverId && hasAdminOrEmployeeRoleHandler()))  && (stockTransferDetails.status == INTENT_STATUS.COURIERED || stockTransferDetails.status == INTENT_STATUS.PENDING_RECEIVED)));

  const statusBodyTemplate = (rowData: any, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );
  };

  const isShowPrintStockTransfer=()=>{
    return (stockTransferDetail?.status == INTENT_STATUS.RECEIVED && (hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler()))
  }

  const isShowEmailStockTransfer=()=>{
    return (stockTransferDetail?.status == INTENT_STATUS.RECEIVED && hasAdminOrEmployeeRoleHandler())
  }

  const printStockTransfer=()=>{
    dispatch(printStockTransferLoading(id))
  }

  const emailStockTransfer=()=>{
    dispatch(emailStockTransferLoading(id))
  }
  return (
    <div className="pagescontent " id="view-dealer">
      {visible && dialogContainer(error)}
      <ArchiveModal
        show={generateSKUModal}
        message="The system will automatically generate SKU. when parts cannot be scanned."
        deleteLabel="Yes"
        closeLabel="No"
        onDeleteClick={() => {
          setGenerateSKUModal(false);
        }}
        onCloseClick={() => {
          setGenerateSKUModal(false);
        }}
      />
      <BreadcrumbWithFilter
        title="Stock Transfer"
        titlePath={`${ROUTER.STOCK_TRANSFER_GRID}`}
        breadcrumbItem="Stock Transfer Details"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={hasEdit}
        firstButtonLabel="Edit Stock Transfer"
        onFirstButtonClick={handleEditStockTransfer}
        isShowSecondButton={isShowPrintStockTransfer()}
        secondButtonLabel='Print Stock Transfer'
        onSecondButtonClick={printStockTransfer}
        isShowThirdButton={isShowEmailStockTransfer()}
        thirdButtonLabel='Email Stock Transfer'
        onThirdButtonClick={emailStockTransfer}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <div className="dis-flex-btw">
              <b>Stock Transfer Details</b>
              <div
                className={`intent-status ${
                  stockTransferDetail?.status == INTENT_STATUS.APPROVED
                    ? "grn-color"
                    : ""
                } ${
                  stockTransferDetail?.status == INTENT_STATUS.REJECTED
                    ? "red-color"
                    : ""
                }`}
              >
                {stockTransferDetail?.status == INTENT_STATUS.PENDING_DISPATCH ? 'Pending Dispatch' : stockTransferDetail?.status == INTENT_STATUS.PENDING_RECEIVED ? 'Pending Received' : stockTransferDetails?.status}
              </div>
            </div>
          </div>

          <div className="subview">
            <div className="parentCls">
              {userView.generalDetails.map((data, index) => {
                return (
                  <div key={index} className={` pt-1 childCls`}>
                    <div>{data.name}</div>
                    <div>
                      {data.value ? (
                        data.value
                      ) : (
                        <div className="none-text">-</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <Row>
              <Col lg={4}>
                <div className="card ps-0 pe-0 mt-4" id="table-border">
                  <DataTable size='large' value={StockTransferDetailnondetail} showGridlines tableStyle={{ minWidth: '30rem', padding: "30px" }}>
                    <Column field="partNo" header="Part" className='width-80-per'></Column>
                    <Column field="partQuantity" header="Quantity" className="align-right"></Column>
                  </DataTable>
                </div>
              </Col>
            </Row> */}
            {StockTransferDetailnondetail?.length > 0 && (
              <Row className="mt-2">
                <Col lg={12}>
                  {StockTransferDetailnondetail?.map((part: any, index: any) => {
                    return (
                      <Row className="mt-2" key={index}>
                        <div className="view">
                          <div className="general-details">
                            <b>{`Part Number: ${part.partNo}`}</b>
                            <b>{`Quantity: ${part.partQuantity}`}</b>
                          </div>
                          <div className="">
                            {part.stockTransferDeliverables?.length>0 ? 
                            <div className="p-2" id="table-border">
                              <DataTable
                                size="large"
                                 scrollable scrollHeight="500px"
                                value={part.stockTransferDeliverables}
                                showGridlines
                                tableStyle={{
                                  minWidth: "30rem",
                                  padding: "30px",
                                }}
                              >
                                <Column
                                  field="partSkuNo"
                                  header="Part Sku Number"
                                ></Column>
                                <Column field="partNo" header="Part"></Column>
                                <Column
                                  field="received"
                                  header="Received"
                                  body={(rowData: any) =>
                                    statusBodyTemplate(rowData, "received")
                                  }
                                ></Column>
                              </DataTable>
                            </div> : <div className='p-3'>No PartSku Found</div>}
                          </div>
                        </div>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
}

export default ViewStockTransferComponent